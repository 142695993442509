export const DROPDOWN_CURRENCY_CODES = [
    {
        value: 'INR',
        label: 'INR',
    },
    {
        value: 'USD',
        label: 'USD',
    },
]
