export const DROPDOWN_REWARD_TYPES = [
    {
        value: 'google play',
        label: 'Google Play',
    },
    {
        value: 'amazon',
        label: 'Amazon',
    },
    {
        value: 'flipkart',
        label: 'Flipkart',
    },
    {
        value: 'paytm',
        label: 'Paytm',
    },
    {
        value: 'other',
        label: 'Other',
    },
]
