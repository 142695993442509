import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useApp } from 'src/hooks'
import 'src/styles/FingoSwitchTheme.styles.css'

const FingoSwitchTheme = () => {
    const dispatch = useDispatch()
    const { app_isDarkTheme, app_togglePaletteMode } = useApp()

    // Get the stored theme from local storage or use "light" as the default
    const storedTheme = localStorage.getItem('theme') || 'light'

    // Set the theme based on the stored value
    document.documentElement.setAttribute('data-theme', storedTheme)

    const onClick = useCallback(() => {
        dispatch(app_togglePaletteMode())
        const nextValue = app_isDarkTheme ? 'light' : 'dark'
        localStorage.setItem('theme', nextValue)
        document.documentElement.setAttribute('data-theme', nextValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app_isDarkTheme])

    return (
        <div className='FingoSwitchTheme' onClick={onClick}>
            <div
                className={`FingoSwitchThemeInner ${
                    !app_isDarkTheme ? 'isDark' : 'isLight'
                }`}
            >
                {!app_isDarkTheme ? (
                    <svg
                        width='1386'
                        height='679'
                        viewBox='0 0 1386 679'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <g filter='url(#filter0_d_102_221)'>
                            <path
                                d='M1199.69 468.674C1266.49 468.674 1320.65 412.236 1320.65 342.617C1320.65 272.997 1266.49 216.56 1199.69 216.56C1137.73 216.56 1086.65 265.128 1079.58 327.732C1074.52 326.893 1069.33 326.456 1064.03 326.456C1017.44 326.456 978.829 360.283 971.958 404.457C963.865 402.078 955.267 400.797 946.353 400.797C914.414 400.797 886.512 417.243 871.491 441.721C862.808 438.258 853.32 436.352 843.382 436.352C822.072 436.352 802.832 445.118 789.15 459.203C775.191 434.214 745.439 416.958 710.991 416.958C688.781 416.958 668.523 424.131 653.191 435.925C632.795 407.377 591.677 387.868 544.276 387.868C476.574 387.868 421.691 427.664 421.691 476.754C421.691 525.845 476.574 565.641 544.276 565.641C582.568 565.641 616.76 552.91 639.239 532.967C654.817 552.684 681.141 565.641 710.991 565.641C736.304 565.641 759.082 556.323 774.921 541.466C786.756 567.167 812.959 585.034 843.382 585.034C868.113 585.034 890.055 573.228 903.757 555.005C916.336 561.776 930.87 565.641 946.353 565.641C986.504 565.641 1020.27 539.652 1030.1 504.389C1040.61 508.459 1052.06 510.693 1064.03 510.693C1101.63 510.693 1134.02 488.678 1148.74 456.976C1164.23 464.483 1181.49 468.674 1199.69 468.674Z'
                                fill='#ffffff'
                                opacity='0.2'
                            />
                        </g>
                        <g filter='url(#filter1_d_102_221)'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M1258.54 510.693C1325.33 510.693 1379.49 454.255 1379.49 384.636C1379.49 315.017 1325.33 258.579 1258.54 258.579C1196.57 258.579 1145.49 307.147 1138.42 369.751C1133.36 368.912 1128.17 368.475 1122.88 368.475C1076.28 368.475 1037.67 402.302 1030.8 446.476C1022.71 444.097 1014.11 442.816 1005.19 442.816C973.255 442.816 945.352 459.263 930.332 483.74C921.648 480.277 912.161 478.371 902.223 478.371C880.912 478.371 861.672 487.137 847.991 501.223C834.032 476.234 804.28 458.977 769.831 458.977C747.621 458.977 727.364 466.15 712.032 477.944C691.636 449.396 650.518 429.887 603.116 429.887C535.415 429.887 480.532 469.683 480.532 518.774C480.532 567.864 535.415 607.66 603.116 607.66C641.409 607.66 675.6 594.929 698.08 574.986C713.658 594.703 739.981 607.66 769.831 607.66C795.145 607.66 817.923 598.342 833.761 583.486C845.597 609.186 871.8 627.053 902.223 627.053C926.953 627.053 948.895 615.247 962.598 597.024C975.176 603.795 989.711 607.66 1005.19 607.66C1045.34 607.66 1079.11 581.671 1088.94 546.409C1099.45 550.478 1110.9 552.712 1122.88 552.712C1160.47 552.712 1192.86 530.697 1207.58 498.995C1223.07 506.502 1240.33 510.693 1258.54 510.693Z'
                                fill='#ffffff'
                                opacity='0.45'
                            />
                        </g>
                        <ellipse
                            cx='738.777'
                            cy='339.385'
                            rx='339.968'
                            ry='339.385'
                            fill='#F7F8FB'
                            fillOpacity='0.1'
                        />
                        <ellipse
                            cx='691.377'
                            cy='339.385'
                            rx='292.569'
                            ry='290.901'
                            fill='#F7F8FB'
                            fillOpacity='0.2'
                        />
                        <ellipse
                            cx='643.978'
                            cy='339.385'
                            rx='245.169'
                            ry='242.418'
                            fill='#F7F8FB'
                            fillOpacity='0.3'
                        />
                        <path
                            d='M81.723 206.863L84.9984 223.866C85.2312 225.074 86.1791 226.018 87.3889 226.246L104.606 229.489L87.3889 232.731C86.1791 232.959 85.2312 233.903 84.9984 235.112L81.723 252.114L78.4477 235.112C78.2148 233.903 77.267 232.959 76.0571 232.731L58.8406 229.489L76.0571 226.246C77.267 226.018 78.2148 225.074 78.4477 223.866L81.723 206.863Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M389.002 252.114L392.277 269.117C392.51 270.325 393.458 271.269 394.668 271.497L411.884 274.74L394.668 277.982C393.458 278.21 392.51 279.154 392.277 280.363L389.002 297.365L385.727 280.363C385.494 279.154 384.546 278.21 383.336 277.982L366.12 274.74L383.336 271.497C384.546 271.269 385.494 270.325 385.727 269.117L389.002 252.114Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M284.396 378.172L287.672 395.174C287.904 396.383 288.852 397.327 290.062 397.555L307.279 400.797L290.062 404.04C288.852 404.268 287.904 405.211 287.672 406.42L284.396 423.423L281.121 406.42C280.888 405.211 279.94 404.268 278.73 404.04L261.514 400.797L278.73 397.555C279.94 397.327 280.888 396.383 281.121 395.174L284.396 378.172Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M124.219 310.295L126.971 324.582C127.204 325.791 128.152 326.735 129.362 326.963L143.833 329.688L129.362 332.413C128.152 332.641 127.204 333.585 126.971 334.794L124.219 349.081L121.467 334.794C121.234 333.585 120.286 332.641 119.076 332.413L104.606 329.688L119.076 326.963C120.286 326.735 121.234 325.791 121.467 324.582L124.219 310.295Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M245.169 362.01L246.353 368.153C246.585 369.361 247.533 370.305 248.743 370.533L254.976 371.707L248.743 372.881C247.533 373.109 246.585 374.053 246.353 375.262L245.169 381.404L243.986 375.262C243.753 374.053 242.805 373.109 241.596 372.881L235.363 371.707L241.596 370.533C242.805 370.305 243.753 369.361 243.986 368.153L245.169 362.01Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M346.506 371.707L348.212 380.564C348.445 381.773 349.393 382.717 350.603 382.945L359.582 384.636L350.603 386.327C349.393 386.555 348.445 387.499 348.212 388.708L346.506 397.565L344.8 388.708C344.567 387.499 343.619 386.555 342.409 386.327L333.43 384.636L342.409 382.945C343.619 382.717 344.567 381.773 344.8 380.564L346.506 371.707Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M304.01 216.56L305.193 222.702C305.426 223.911 306.374 224.855 307.584 225.082L313.817 226.256L307.584 227.43C306.374 227.658 305.426 228.602 305.193 229.811L304.01 235.953L302.827 229.811C302.594 228.602 301.646 227.658 300.436 227.43L294.203 226.256L300.436 225.082C301.646 224.855 302.594 223.911 302.827 222.702L304.01 216.56Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M323.623 446.048L324.807 452.19C325.04 453.399 325.987 454.343 327.197 454.571L333.43 455.745L327.197 456.919C325.987 457.147 325.04 458.091 324.807 459.3L323.623 465.442L322.44 459.3C322.207 458.091 321.259 457.147 320.05 456.919L313.817 455.745L320.05 454.571C321.259 454.343 322.207 453.399 322.44 452.19L323.623 446.048Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M52.3026 368.475L53.4858 374.617C53.7187 375.826 54.6665 376.77 55.8764 376.998L62.1094 378.172L55.8764 379.345C54.6665 379.573 53.7187 380.517 53.4858 381.726L52.3026 387.868L51.1194 381.726C50.8865 380.517 49.9387 379.573 48.7288 379.345L42.4958 378.172L48.7288 376.998C49.9387 376.77 50.8865 375.826 51.1194 374.617L52.3026 368.475Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M22.8823 410.494L24.5886 419.351C24.8215 420.56 25.7693 421.504 26.9792 421.732L35.958 423.423L26.9792 425.114C25.7693 425.342 24.8215 426.286 24.5886 427.495L22.8823 436.352L21.1761 427.495C20.9432 426.286 19.9954 425.342 18.7855 425.114L9.80664 423.423L18.7855 421.732C19.9954 421.504 20.9432 420.56 21.1761 419.351L22.8823 410.494Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M13.0757 258.579L14.7819 267.436C15.0148 268.645 15.9626 269.589 17.1725 269.817L26.1514 271.508L17.1725 273.199C15.9626 273.427 15.0148 274.371 14.7819 275.579L13.0757 284.437L11.3695 275.579C11.1366 274.371 10.1887 273.427 8.97888 273.199L0 271.508L8.97888 269.817C10.1887 269.589 11.1366 268.645 11.3695 267.436L13.0757 258.579Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M78.4541 391.1L81.2064 405.388C81.4393 406.597 82.3871 407.541 83.597 407.768L98.0677 410.494L83.597 413.219C82.3871 413.447 81.4393 414.391 81.2064 415.6L78.4541 429.887L75.7018 415.6C75.4689 414.391 74.5211 413.447 73.3112 413.219L58.8406 410.494L73.3112 407.768C74.5211 407.541 75.4689 406.597 75.7018 405.388L78.4541 391.1Z'
                            fill='#C5C7CF'
                        />
                        <circle cx='638' cy='327.425' r='175' fill='#E9C948' />
                        <g filter='url(#filter2_ddii_102_221)'>
                            <ellipse
                                cx='638'
                                cy='327.425'
                                rx='162.5'
                                ry='162.5'
                                fill='#E9C948'
                                fillOpacity='0.2'
                                shapeRendering='crispEdges'
                            />
                        </g>
                        <defs>
                            <filter
                                id='filter0_d_102_221'
                                x='416.691'
                                y='211.56'
                                width='908.954'
                                height='378.475'
                                filterUnits='userSpaceOnUse'
                                colorInterpolationFilters='sRGB'
                            >
                                <feFlood
                                    floodOpacity='0'
                                    result='BackgroundImageFix'
                                />
                                <feColorMatrix
                                    in='SourceAlpha'
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                    result='hardAlpha'
                                />
                                <feOffset />
                                <feGaussianBlur stdDeviation='2.5' />
                                <feComposite in2='hardAlpha' operator='out' />
                                <feColorMatrix
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                                />
                                <feBlend
                                    mode='normal'
                                    in2='BackgroundImageFix'
                                    result='effect1_dropShadow_102_221'
                                />
                                <feBlend
                                    mode='normal'
                                    in='SourceGraphic'
                                    in2='effect1_dropShadow_102_221'
                                    result='shape'
                                />
                            </filter>
                            <filter
                                id='filter1_d_102_221'
                                x='474.532'
                                y='252.579'
                                width='910.954'
                                height='380.475'
                                filterUnits='userSpaceOnUse'
                                colorInterpolationFilters='sRGB'
                            >
                                <feFlood
                                    floodOpacity='0'
                                    result='BackgroundImageFix'
                                />
                                <feColorMatrix
                                    in='SourceAlpha'
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                    result='hardAlpha'
                                />
                                <feOffset />
                                <feGaussianBlur stdDeviation='3' />
                                <feComposite in2='hardAlpha' operator='out' />
                                <feColorMatrix
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                                />
                                <feBlend
                                    mode='normal'
                                    in2='BackgroundImageFix'
                                    result='effect1_dropShadow_102_221'
                                />
                                <feBlend
                                    mode='normal'
                                    in='SourceGraphic'
                                    in2='effect1_dropShadow_102_221'
                                    result='shape'
                                />
                            </filter>
                            <filter
                                id='filter2_ddii_102_221'
                                x='464.5'
                                y='153.925'
                                width='347'
                                height='347'
                                filterUnits='userSpaceOnUse'
                                colorInterpolationFilters='sRGB'
                            >
                                <feFlood
                                    floodOpacity='0'
                                    result='BackgroundImageFix'
                                />
                                <feColorMatrix
                                    in='SourceAlpha'
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                    result='hardAlpha'
                                />
                                <feOffset dx='4' dy='4' />
                                <feGaussianBlur stdDeviation='3.5' />
                                <feComposite in2='hardAlpha' operator='out' />
                                <feColorMatrix
                                    type='matrix'
                                    values='0 0 0 0 0.67451 0 0 0 0 0.584314 0 0 0 0 0.203922 0 0 0 0.35 0'
                                />
                                <feBlend
                                    mode='normal'
                                    in2='BackgroundImageFix'
                                    result='effect1_dropShadow_102_221'
                                />
                                <feColorMatrix
                                    in='SourceAlpha'
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                    result='hardAlpha'
                                />
                                <feOffset dx='-4' dy='-4' />
                                <feGaussianBlur stdDeviation='3.5' />
                                <feComposite in2='hardAlpha' operator='out' />
                                <feColorMatrix
                                    type='matrix'
                                    values='0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0 0.662745 0 0 0 0.35 0'
                                />
                                <feBlend
                                    mode='normal'
                                    in2='effect1_dropShadow_102_221'
                                    result='effect2_dropShadow_102_221'
                                />
                                <feBlend
                                    mode='normal'
                                    in='SourceGraphic'
                                    in2='effect2_dropShadow_102_221'
                                    result='shape'
                                />
                                <feColorMatrix
                                    in='SourceAlpha'
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                    result='hardAlpha'
                                />
                                <feOffset dx='4' dy='4' />
                                <feGaussianBlur stdDeviation='3.5' />
                                <feComposite
                                    in2='hardAlpha'
                                    operator='arithmetic'
                                    k2='-1'
                                    k3='1'
                                />
                                <feColorMatrix
                                    type='matrix'
                                    values='0 0 0 0 1 0 0 0 0 0.932919 0 0 0 0 0.6625 0 0 0 0.35 0'
                                />
                                <feBlend
                                    mode='normal'
                                    in2='shape'
                                    result='effect3_innerShadow_102_221'
                                />
                                <feColorMatrix
                                    in='SourceAlpha'
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                    result='hardAlpha'
                                />
                                <feOffset dx='-4' dy='-4' />
                                <feGaussianBlur stdDeviation='3.5' />
                                <feComposite
                                    in2='hardAlpha'
                                    operator='arithmetic'
                                    k2='-1'
                                    k3='1'
                                />
                                <feColorMatrix
                                    type='matrix'
                                    values='0 0 0 0 0.675833 0 0 0 0 0.582364 0 0 0 0 0.205566 0 0 0 0.35 0'
                                />
                                <feBlend
                                    mode='normal'
                                    in2='effect3_innerShadow_102_221'
                                    result='effect4_innerShadow_102_221'
                                />
                            </filter>
                        </defs>
                    </svg>
                ) : (
                    <svg
                        width='1687'
                        height='679'
                        viewBox='0 0 1687 679'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <g filter='url(#filter0_d_102_188)'>
                            <path
                                d='M1500.69 467.674C1567.49 467.674 1621.65 411.236 1621.65 341.617C1621.65 271.997 1567.49 215.56 1500.69 215.56C1438.73 215.56 1387.65 264.128 1380.58 326.732C1375.52 325.893 1370.33 325.456 1365.03 325.456C1318.44 325.456 1279.83 359.283 1272.96 403.457C1264.87 401.078 1256.27 399.797 1247.35 399.797C1215.41 399.797 1187.51 416.243 1172.49 440.721C1163.81 437.258 1154.32 435.352 1144.38 435.352C1123.07 435.352 1103.83 444.118 1090.15 458.203C1076.19 433.214 1046.44 415.958 1011.99 415.958C989.781 415.958 969.523 423.131 954.191 434.925C933.795 406.377 892.677 386.868 845.276 386.868C777.574 386.868 722.691 426.664 722.691 475.755C722.691 524.845 777.574 564.641 845.276 564.641C883.568 564.641 917.76 551.91 940.239 531.967C955.817 551.684 982.141 564.641 1011.99 564.641C1037.3 564.641 1060.08 555.323 1075.92 540.466C1087.76 566.167 1113.96 584.034 1144.38 584.034C1169.11 584.034 1191.05 572.228 1204.76 554.005C1217.34 560.776 1231.87 564.641 1247.35 564.641C1287.5 564.641 1321.27 538.652 1331.1 503.39C1341.61 507.459 1353.06 509.693 1365.03 509.693C1402.63 509.693 1435.02 487.678 1449.74 455.976C1465.23 463.483 1482.49 467.674 1500.69 467.674Z'
                                fill='#A5C7F0'
                            />
                        </g>
                        <g filter='url(#filter1_d_102_188)'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M1559.54 509.693C1626.33 509.693 1680.49 453.255 1680.49 383.636C1680.49 314.016 1626.33 257.579 1559.54 257.579C1497.57 257.579 1446.49 306.147 1439.42 368.751C1434.36 367.911 1429.17 367.474 1423.88 367.474C1377.28 367.474 1338.67 401.301 1331.8 445.476C1323.71 443.097 1315.11 441.816 1306.19 441.816C1274.25 441.816 1246.35 458.262 1231.33 482.74C1222.65 479.277 1213.16 477.37 1203.22 477.37C1181.91 477.37 1162.67 486.137 1148.99 500.222C1135.03 475.233 1105.28 457.977 1070.83 457.977C1048.62 457.977 1028.36 465.15 1013.03 476.944C992.636 448.396 951.518 428.887 904.116 428.887C836.415 428.887 781.532 468.683 781.532 517.773C781.532 566.864 836.415 606.66 904.116 606.66C942.409 606.66 976.6 593.929 999.08 573.986C1014.66 593.703 1040.98 606.66 1070.83 606.66C1096.15 606.66 1118.92 597.342 1134.76 582.485C1146.6 608.186 1172.8 626.053 1203.22 626.053C1227.95 626.053 1249.9 614.247 1263.6 596.024C1276.18 602.795 1290.71 606.66 1306.19 606.66C1346.34 606.66 1380.11 580.671 1389.94 545.408C1400.45 549.478 1411.9 551.712 1423.88 551.712C1461.47 551.712 1493.86 529.697 1508.58 497.995C1524.07 505.502 1541.33 509.693 1559.54 509.693Z'
                                fill='#FBFDFF'
                            />
                        </g>
                        <ellipse
                            cx='469.777'
                            cy='339.385'
                            rx='339.968'
                            ry='339.385'
                            fill='#F7F8FB'
                            fillOpacity='0.05'
                        />
                        <ellipse
                            cx='502.377'
                            cy='339.385'
                            rx='292.569'
                            ry='290.901'
                            fill='#F7F8FB'
                            fillOpacity='0.08'
                        />
                        <ellipse
                            cx='524.978'
                            cy='339.385'
                            rx='245.169'
                            ry='242.418'
                            fill='#F7F8FB'
                            fillOpacity='0.1'
                        />
                        <path
                            d='M32.3977 221.616L35.2552 236.45C35.4881 237.659 36.436 238.603 37.6458 238.831L52.669 241.66L37.6458 244.489C36.436 244.717 35.4881 245.661 35.2552 246.87L32.3977 261.704L29.5402 246.87C29.3073 245.661 28.3594 244.717 27.1496 244.489L12.1263 241.66L27.1496 238.831C28.3594 238.603 29.3073 237.659 29.5402 236.45L32.3977 221.616Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M32.3977 221.616L35.2552 236.45C35.4881 237.659 36.436 238.603 37.6458 238.831L52.669 241.66L37.6458 244.489C36.436 244.717 35.4881 245.661 35.2552 246.87L32.3977 261.704L29.5402 246.87C29.3073 245.661 28.3594 244.717 27.1496 244.489L12.1263 241.66L27.1496 238.831C28.3594 238.603 29.3073 237.659 29.5402 236.45L32.3977 221.616Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M197.271 174.425L200.129 189.259C200.362 190.468 201.31 191.411 202.519 191.639L217.543 194.469L202.519 197.298C201.31 197.526 200.362 198.47 200.129 199.679L197.271 214.513L194.414 199.679C194.181 198.47 193.233 197.526 192.023 197.298L177 194.469L192.023 191.639C193.233 191.411 194.181 190.468 194.414 189.259L197.271 174.425Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M165.271 273.425L168.129 288.259C168.362 289.468 169.31 290.411 170.519 290.639L185.543 293.469L170.519 296.298C169.31 296.526 168.362 297.47 168.129 298.679L165.271 313.513L162.414 298.679C162.181 297.47 161.233 296.526 160.023 296.298L145 293.469L160.023 290.639C161.233 290.411 162.181 289.468 162.414 288.259L165.271 273.425Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M267.375 439.425L269.77 451.853C270.003 453.062 270.95 454.006 272.16 454.234L284.751 456.605L272.16 458.977C270.95 459.205 270.003 460.148 269.77 461.357L267.375 473.786L264.981 461.357C264.748 460.148 263.801 459.205 262.591 458.977L250 456.605L262.591 454.234C263.801 454.006 264.748 453.062 264.981 451.853L267.375 439.425Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M185.688 413.425L186.692 418.638C186.925 419.846 187.873 420.79 189.082 421.018L194.375 422.015L189.082 423.012C187.873 423.24 186.925 424.184 186.692 425.393L185.688 430.605L184.684 425.393C184.451 424.184 183.503 423.24 182.293 423.012L177 422.015L182.293 421.018C183.503 420.79 184.451 419.846 184.684 418.638L185.688 413.425Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M266.584 307.425L268.051 315.043C268.284 316.252 269.232 317.196 270.442 317.424L278.167 318.879L270.442 320.334C269.232 320.561 268.284 321.505 268.051 322.714L266.584 330.332L265.116 322.714C264.883 321.505 263.935 320.561 262.726 320.334L255 318.879L262.726 317.424C263.935 317.196 264.883 316.252 265.116 315.043L266.584 307.425Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M121.688 227.425L122.692 232.638C122.925 233.846 123.873 234.79 125.082 235.018L130.375 236.015L125.082 237.012C123.873 237.24 122.925 238.184 122.692 239.393L121.688 244.605L120.684 239.393C120.451 238.184 119.503 237.24 118.293 237.012L113 236.015L118.293 235.018C119.503 234.79 120.451 233.846 120.684 232.638L121.688 227.425Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M228.688 409.425L229.692 414.638C229.925 415.846 230.873 416.79 232.082 417.018L237.375 418.015L232.082 419.012C230.873 419.24 229.925 420.184 229.692 421.393L228.688 426.605L227.684 421.393C227.451 420.184 226.503 419.24 225.293 419.012L220 418.015L225.293 417.018C226.503 416.79 227.451 415.846 227.684 414.638L228.688 409.425Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M91.6877 318.425L92.6919 323.638C92.9248 324.846 93.8726 325.79 95.0825 326.018L100.375 327.015L95.0825 328.012C93.8726 328.24 92.9248 329.184 92.6919 330.393L91.6877 335.605L90.6836 330.393C90.4507 329.184 89.5028 328.24 88.293 328.012L83 327.015L88.293 326.018C89.5028 325.79 90.4507 324.846 90.6836 323.638L91.6877 318.425Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M209.584 335.425L211.051 343.043C211.284 344.252 212.232 345.196 213.442 345.424L221.167 346.879L213.442 348.334C212.232 348.561 211.284 349.505 211.051 350.714L209.584 358.332L208.116 350.714C207.883 349.505 206.935 348.561 205.726 348.334L198 346.879L205.726 345.424C206.935 345.196 207.883 344.252 208.116 343.043L209.584 335.425Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M11.5836 323.425L13.0511 331.043C13.284 332.252 14.2319 333.196 15.4417 333.424L23.1673 334.879L15.4417 336.334C14.2319 336.561 13.284 337.505 13.0511 338.714L11.5836 346.332L10.1161 338.714C9.88325 337.505 8.93541 336.561 7.72554 336.334L0 334.879L7.72554 333.424C8.93541 333.196 9.88325 332.252 10.1161 331.043L11.5836 323.425Z'
                            fill='#C5C7CF'
                        />
                        <path
                            d='M100.375 413.425L102.77 425.853C103.003 427.062 103.95 428.006 105.16 428.234L117.751 430.605L105.16 432.977C103.95 433.205 103.003 434.148 102.77 435.357L100.375 447.786L97.9813 435.357C97.7484 434.148 96.8005 433.205 95.5907 432.977L83 430.605L95.5907 428.234C96.8005 428.006 97.7484 427.062 97.9813 425.853L100.375 413.425Z'
                            fill='#C5C7CF'
                        />
                        <circle cx='519' cy='327.425' r='175' fill='#C5C7CF' />
                        <ellipse
                            cx='458.583'
                            cy='375.342'
                            rx='52.0833'
                            ry='52.0833'
                            fill='#9497AC'
                        />
                        <ellipse
                            cx='581.5'
                            cy='406.592'
                            rx='33.3333'
                            ry='33.3333'
                            fill='#9497AC'
                        />
                        <ellipse
                            cx='510.667'
                            cy='244.092'
                            rx='29.1667'
                            ry='29.1667'
                            fill='#9497AC'
                        />
                        <g filter='url(#filter2_ddii_102_188)'>
                            <ellipse
                                cx='519'
                                cy='327.425'
                                rx='162.5'
                                ry='162.5'
                                fill='#C5C7CF'
                                fillOpacity='0.3'
                                shapeRendering='crispEdges'
                            />
                        </g>
                        <defs>
                            <filter
                                id='filter0_d_102_188'
                                x='717.691'
                                y='210.56'
                                width='908.954'
                                height='378.475'
                                filterUnits='userSpaceOnUse'
                                colorInterpolationFilters='sRGB'
                            >
                                <feFlood
                                    floodOpacity='0'
                                    result='BackgroundImageFix'
                                />
                                <feColorMatrix
                                    in='SourceAlpha'
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                    result='hardAlpha'
                                />
                                <feOffset />
                                <feGaussianBlur stdDeviation='2.5' />
                                <feComposite in2='hardAlpha' operator='out' />
                                <feColorMatrix
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                                />
                                <feBlend
                                    mode='normal'
                                    in2='BackgroundImageFix'
                                    result='effect1_dropShadow_102_188'
                                />
                                <feBlend
                                    mode='normal'
                                    in='SourceGraphic'
                                    in2='effect1_dropShadow_102_188'
                                    result='shape'
                                />
                            </filter>
                            <filter
                                id='filter1_d_102_188'
                                x='775.532'
                                y='251.579'
                                width='910.954'
                                height='380.475'
                                filterUnits='userSpaceOnUse'
                                colorInterpolationFilters='sRGB'
                            >
                                <feFlood
                                    floodOpacity='0'
                                    result='BackgroundImageFix'
                                />
                                <feColorMatrix
                                    in='SourceAlpha'
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                    result='hardAlpha'
                                />
                                <feOffset />
                                <feGaussianBlur stdDeviation='3' />
                                <feComposite in2='hardAlpha' operator='out' />
                                <feColorMatrix
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                                />
                                <feBlend
                                    mode='normal'
                                    in2='BackgroundImageFix'
                                    result='effect1_dropShadow_102_188'
                                />
                                <feBlend
                                    mode='normal'
                                    in='SourceGraphic'
                                    in2='effect1_dropShadow_102_188'
                                    result='shape'
                                />
                            </filter>
                            <filter
                                id='filter2_ddii_102_188'
                                x='345.5'
                                y='153.925'
                                width='347'
                                height='347'
                                filterUnits='userSpaceOnUse'
                                colorInterpolationFilters='sRGB'
                            >
                                <feFlood
                                    floodOpacity='0'
                                    result='BackgroundImageFix'
                                />
                                <feColorMatrix
                                    in='SourceAlpha'
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                    result='hardAlpha'
                                />
                                <feOffset dx='4' dy='4' />
                                <feGaussianBlur stdDeviation='3.5' />
                                <feComposite in2='hardAlpha' operator='out' />
                                <feColorMatrix
                                    type='matrix'
                                    values='0 0 0 0 0.639216 0 0 0 0 0.647059 0 0 0 0 0.717647 0 0 0 0.35 0'
                                />
                                <feBlend
                                    mode='normal'
                                    in2='BackgroundImageFix'
                                    result='effect1_dropShadow_102_188'
                                />
                                <feColorMatrix
                                    in='SourceAlpha'
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                    result='hardAlpha'
                                />
                                <feOffset dx='-4' dy='-4' />
                                <feGaussianBlur stdDeviation='3.5' />
                                <feComposite in2='hardAlpha' operator='out' />
                                <feColorMatrix
                                    type='matrix'
                                    values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0'
                                />
                                <feBlend
                                    mode='normal'
                                    in2='effect1_dropShadow_102_188'
                                    result='effect2_dropShadow_102_188'
                                />
                                <feBlend
                                    mode='normal'
                                    in='SourceGraphic'
                                    in2='effect2_dropShadow_102_188'
                                    result='shape'
                                />
                                <feColorMatrix
                                    in='SourceAlpha'
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                    result='hardAlpha'
                                />
                                <feOffset dx='4' dy='4' />
                                <feGaussianBlur stdDeviation='3.5' />
                                <feComposite
                                    in2='hardAlpha'
                                    operator='arithmetic'
                                    k2='-1'
                                    k3='1'
                                />
                                <feColorMatrix
                                    type='matrix'
                                    values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0'
                                />
                                <feBlend
                                    mode='normal'
                                    in2='shape'
                                    result='effect3_innerShadow_102_188'
                                />
                                <feColorMatrix
                                    in='SourceAlpha'
                                    type='matrix'
                                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                    result='hardAlpha'
                                />
                                <feOffset dx='-4' dy='-4' />
                                <feGaussianBlur stdDeviation='3.5' />
                                <feComposite
                                    in2='hardAlpha'
                                    operator='arithmetic'
                                    k2='-1'
                                    k3='1'
                                />
                                <feColorMatrix
                                    type='matrix'
                                    values='0 0 0 0 0.639216 0 0 0 0 0.647059 0 0 0 0 0.717647 0 0 0 0.35 0'
                                />
                                <feBlend
                                    mode='normal'
                                    in2='effect3_innerShadow_102_188'
                                    result='effect4_innerShadow_102_188'
                                />
                            </filter>
                        </defs>
                    </svg>
                )}
            </div>
        </div>
    )
}

export default FingoSwitchTheme
