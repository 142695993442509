import { Axios } from 'src/api'

export const PartnerApi = {
    admin_getListPartner: async params => {
        const response = await Axios.get(`/server/api/admin/partner`, {
            params,
        })
        return response?.data
    },

    admin_getUserListByPartner: async id => {
        const response = await Axios.get(`/server/api/admin/user-partner/${id}`)
        return response?.data
    },

    admin_createOrUpdatePartner: async (body, isUpdate) => {
        if (body?._id && isUpdate) {
            const response = await Axios.put(
                `/server/api/admin/partner/${body._id}`,
                body
            )
            return response?.data
        } else {
            const response = await Axios.post(`/server/api/admin/partner`, body)
            return response?.data
        }
    },

    admin_deletePartner: async id => {
        const response = await Axios.delete(`/server/api/admin/partner/${id}`)
        return response?.data
    },

    admin_uploadBanner: async body => {
        const response = await Axios.post(
            `/server/api/admin/partner/banner`,
            body
        )
        return response?.data
    },

    admin_uploadLogo: async body => {
        const response = await Axios.post(
            `/server/api/admin/partner/logo`,
            body
        )
        return response?.data
    },
}
