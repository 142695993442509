import { createSlice } from '@reduxjs/toolkit'
import {
    partner_getListPartner,
    partner_getUserListByPartner,
} from './partner.thunk'

// Initial state
const initialState = {
    litUserByPartnerData: [],
    litUserByPartnerIsLoading: false,
    litUserByPartnerIsError: false,

    listPartnerData: null,
    listPartnerIsLoading: false,
    listPartnerIsError: false,
}

// Actual Slice
export const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        partner_reset: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(partner_getListPartner.pending, state => {
            state.listPartnerIsLoading = true
            state.listPartnerIsError = false
        })
        builder.addCase(partner_getListPartner.rejected, (state, action) => {
            state.listPartnerIsLoading = false
            state.listPartnerIsError = true
        })
        builder.addCase(partner_getListPartner.fulfilled, (state, action) => {
            state.listPartnerIsError = false
            state.listPartnerIsLoading = false
            state.listPartnerData = action.payload?.data || []
        })

        builder.addCase(partner_getUserListByPartner.pending, state => {
            state.litUserByPartnerIsLoading = true
            state.litUserByPartnerIsError = false
            state.litUserByPartnerData = null
        })
        builder.addCase(
            partner_getUserListByPartner.rejected,
            (state, action) => {
                state.litUserByPartnerIsLoading = false
                state.litUserByPartnerIsError = true
                state.litUserByPartnerData = null
            }
        )
        builder.addCase(
            partner_getUserListByPartner.fulfilled,
            (state, action) => {
                state.litUserByPartnerIsError = false
                state.litUserByPartnerIsLoading = false
                state.litUserByPartnerData = action.payload?.data || null
            }
        )
    },
})

export const partner_reducerActions = partnerSlice.actions

export const partner_selectState = state => state.partner
